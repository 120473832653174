import React from "react";
import styled from "styled-components";
import './Brand.scss';

const MainBrand = styled.div`
   img{
    width : ${props => props.width};
    max-width: 100%;
    height: ${props => props.height};
    object-fit: cover;
    object-position: center center;
   }
   .v-light & , & {
        .logo-dark{
           display : block ;     
        }
        .logo-light{
            display : none;    
        }
   }
    
   .v-dark & {
       .logo-dark{
           display : none ;     
        }
        .logo-light{
            display : block;    
        }
   }
   
`;

const Brand = ({width, height, alt}) => {
    return (
        <div className="brand-wrapper">
            <div className="box" />
            <MainBrand className="main-brand" width={width} height={height}>
                <img className="logo-light" src="/logo-2.png" alt={`${alt} - logo light`} width={width} height={height}/>
                <img className="logo-dark" src="/logo-2.png" alt={`${alt} - logo dark`} width={width} height={height}/>
            </MainBrand>
        </div>
    );
}

MainBrand.defaultProps = {width: '200px', height: 'auto'}
Brand.defaultProps = {alt: "RED Designs"}

export default React.memo(Brand);