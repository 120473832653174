import React from 'react';
import './FloatingButton.scss';
import {faPhone} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from "react-router-dom";
import { useLocation } from 'react-router-dom';

const FloatingButton = () => {
  const location = useLocation();
  if (location.pathname.startsWith('/contact')) {
    return <></>;
  }
  return (
    <div className="floating-action-button">
      <Link to="/contact">
        <FontAwesomeIcon icon={faPhone}/>
      </Link>
    </div>
  );
};

export default FloatingButton;
