import React, {useState, Suspense} from "react";
import {useSelector} from "react-redux";
import {Route, Routes} from "react-router-dom";
import useEffectLocation from "../hooks/useEffectLocation";

import './style.scss';

const MainDemo = React.lazy(() => import("../views/home/MainDemo"));

const SliderOne = React.lazy(() => import("../views/slider/SliderOne"));

const Work = React.lazy(() => import("../views/Work"));
const ProjectDetails = React.lazy(() => import("../views/portfolio/ProjectDetails"));

const About = React.lazy(() => import("../views/About"));
const Maquette = React.lazy(() => import("../views/Maquette"));
const Contact = React.lazy(() => import("../views/Contact"));
const PrivacyPolicy = React.lazy(() => import("../views/Privacy"));


const Router = () => {

    const [transPage, setTransPage] = useState("in");
    const scrollbar = useSelector(state => state.scrollbar);

    const location = useEffectLocation((l) => {
        setTransPage("out");
    })

    const onAnimateEnd = () => {
        if (transPage !== "out") return;
        scrollbar.current?.scrollTo(0, 0, 0);
        window.scrollTo({left: 0, top: 0});
        setTransPage("in");
    }


    return (

        <div id="dsn-content" className={`dsn-transition-page dsn-animate-${transPage}`}
             onAnimationEnd={onAnimateEnd}
        >
            <Suspense fallback={<div className="background-main h-100-v" />}>
                <Routes location={location}>

                    <Route path="/" element={<MainDemo/>}/>

                    <Route path="/slider" element={<SliderOne/>}/>

                    <Route exact path="/portfolio" element={<Work/>}/>
                    <Route exact path="/portfolio/:slug" element={<ProjectDetails/>}/>

                    <Route exact path="/about" element={<About/>}/>
                    <Route exact path="/digital-maquette" element={<Maquette/>}/>
                    <Route exact path="/contact" element={<Contact/>}/>
                    <Route exact path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route exact path="*" element={<h1>Not Found</h1>}/>
                </Routes>
            </Suspense>
        </div>


    );
}

export default React.memo(Router);