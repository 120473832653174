import React from 'react';

import MainScrollBar from "./MainScrollBar";
import DroowMenu from "../components/header/menu/DroowMenu";
import LoadingPage from "../hooks/LoadingPage";
import CustomCursor from "./CustomCursor";
import FloatingButton from "../components/FloatingButton/FloatingButton";

function Layout(
    {className, children, tag: Tag = "div", activeScrollbar, ...restProps}) {

    return (
        <Tag id="main_layout" className={className}>
            <DroowMenu hamburger />
            <LoadingPage/>
            {activeScrollbar ? <MainScrollBar  {...restProps}>{children}</MainScrollBar> : children}
            <CustomCursor
                duration={0.5}
                durationChangeSize={0.3}
                size={30}
                scale={75}
                ease="power2.out"
            />
          <FloatingButton />
        </Tag>
    );
}

export default Layout;